<template>
    <div ref="invoiceFormRef" :style="'font-family: ' + font">
        <div class="box-border pv4" style="background: #fbfcfe">
            <div class="w-90 center box-border">
                <div :style="'height:15px;background:' + color + ';'"></div>
                <div class="pa3-l pa2" style="background: white">
                    <div>
                        <div ref="companyLogoWrapper" v-if="orgImage">
                            <img
                                ref="companyLogo"
                                :src="orgImage"
                                alt=""
                                style="object-position: center; object-fit: cover; width: 60px; height: 60px"
                                class="rounded-circle"
                            />
                        </div>
                        <div v-else class="org-img-placeholder">
                            <span>{{ orgInitials || userInitials || '' }}</span>
                        </div>
                        <div>
                            <div class="pb2x b">{{ invoice.business?.name }}</div>
                            <div class="pb2">{{ invoice.business?.address }}</div>
                            <div class="pb2">{{ invoice.business?.email }}</div>
                            <div class="pb2">{{ invoice.business?.phone }}</div>
                            <div v-if="invoice.business?.tin" class="pb2">TIN: {{ invoice.business?.tin }}</div>
                            <div v-if="invoice.business?.rcNumber" class="pb2">RC No: {{ invoice.business?.rcNumber }}</div>
                        </div>
                        <div v-if="invoice?.description && invoice.description != ''" class="mt3 b">
                            {{ invoice.description }}
                        </div>
                    </div>
                    <div class="flex flex-wrap justify-between pv3">
                        <div class="font-w1 pb3 pb0-l"></div>
                        <div class="b">
                            {{ $route.fullPath.startsWith('/quotations') ? 'QUOTE' : 'INVOICE' }}
                            <invoice-status :invoice="invoice" />
                        </div>
                    </div>
                    <div class="pa3" style="background: #f7f9fd">
                        <div class="flex flex-wrap justify-between bill-section">
                            <div class="pv3">
                                <div class="b pb2" :style="'color:' + color + ';'">Bill to</div>
                                <div>
                                    <div class="pb2 b">{{ invoice.customer?.name }}</div>
                                    <div class="pb2">{{ invoice.customer?.address }}</div>
                                    <div class="pb2">{{ invoice.customer?.country }}</div>
                                    <div class="pb2">{{ invoice.customer?.email }}</div>
                                    <div class="pb2">{{ invoice.customer?.phone }}</div>
                                </div>
                            </div>
                            <div class="pv3 tr-lx tl">
                                <div class="font-w1 pb3 pb0-l">{{ $route.fullPath.startsWith('/quotations') ? 'Quotation' : 'Invoice' }} No: {{ invoice.number }}</div>
                                <div>Date issued: {{ formatDate(invoice.dateIssued) }}</div>
                                <div>Date due: {{ formatDate(invoice.dateDue) }}</div>
                                <div v-if="invoice?.last_payment_date">
                                    Last payment date: {{ formatDate(invoice.last_payment_date) }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="pv3-l pv2">
                        <div
                            class="flex justify-between pa3 box-border-bottom font-w1 table-sbk"
                            :style="'background:' + color + ';'"
                        >
                            <div class="w-30">Item</div>
                            <div class="w-60 flex justify-between">
                                <div class="w-30 tl">Cost</div>
                                <div class="w-30 tl">Qty/Unit</div>
                                <div class="w-30 tr">Price</div>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div v-for="item of invoice.items" v-bind:key="item.id" class="pa2-l pa2">
                            <div class="flex justify-between pb3 box-border-bottom">
                                <div class="w-30" style="word-wrap: break-word !important;">
                                    <p class="b longText flex-wrap w-100" style="word-wrap: break-word !important;">{{ item.name }}</p>
                                    <div v-if="item.notes" class="text-muted longText" style="font-size: 0.9em">{{ item.notes }}</div>
                                </div>
                                <div class="w-60 flex justify-between">
                                    <div class="w-30 tl">{{ item.price }}</div>
                                    <div class="w-30 tl">{{ item.quantity }} {{ item.measurement }}</div>
                                    <div class="w-30 tr">
                                        <div v-if="item.discount > 0">
                                            <div class="text-muted" style="text-decoration: line-through;">
                                                {{
                                                    formatAmount(
                                                        item.price * item.quantity,
                                                        invoice.currency || $store.state.Settings?.currency
                                                    )
                                                }}
                                            </div>
                                            <div>
                                                {{
                                                    formatAmount(item.total, invoice?.currency || $store.state.Settings?.currency)
                                                }}
                                            </div>
                                        </div>
                                        <div v-else>
                                            {{
                                                formatAmount(
                                                    item.price * item.quantity,
                                                    invoice?.currency || $store.state.Settings?.currency
                                                )
                                            }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Subtotal -->
                    <div class="ph3-l ph2">
                        <div class="flex justify-between pb3 font-w1">
                            <div class="w-30-l"></div>
                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                <div class="w-30 tl">Subtotal</div>
                                <div class="w-30 tr">
                                    {{ formatAmount(invoice.subtotal, invoice?.currency || $store.state.Settings?.currency) }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Discount -->
                    <div v-if="invoice.hasDiscount" class="ph3-l ph2">
                        <div class="flex justify-between pb3 font-w1">
                            <div class="w-30-l"></div>
                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                <div class="w-30 tl">Discount</div>
                                <div v-if="invoice.discountType == 'fixed'" class="w-30 tr" style="color: #D2042D">
                                    - {{ formatAmount(invoice.discount, invoice?.currency || $store.state.Settings?.currency) }}
                                </div>
                                <div v-else class="w-30 tr" style="color: #D2042D">-{{ invoice.discount }}%</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="invoice.hasCommission" class="ph3-l ph2">
                        <div class="flex justify-between pb3 font-w1">
                            <div class="w-30-l"></div>
                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                <div class="w-30 tl">Commission</div>
                                <div v-if="invoice.commissionType == 'fixed'" class="w-30 tr">
                                    {{ formatAmount(invoice.commission, invoice?.currency || $store.state.Settings?.currency) }}
                                </div>
                                <div v-else class="w-30 tr">{{ invoice.commission }}%</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="invoice.hasShipping" class="ph3-l ph2">
                        <div class="flex justify-between pb3 font-w1">
                            <div class="w-30-l"></div>
                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                <div class="w-30 tl">Shipping</div>
                                <div v-if="invoice.shippingType == 'fixed'" class="w-30 tr">
                                    {{ formatAmount(invoice.shipping, invoice?.currency || $store.state.Settings?.currency) }}
                                </div>
                                <div v-else class="w-30 tr">{{ invoice.shipping }}%</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="invoice.hasTax" class="ph3-l ph2">
                        <div class="flex justify-between pb3 font-w1">
                            <div class="w-30-l"></div>
                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                <div class="w-30 tl">Tax</div>
                                <div v-if="invoice.taxType == 'fixed'" class="w-30 tr">
                                    {{ formatAmount(invoice.tax, invoice?.currency || $store.state.Settings?.currency) }}
                                </div>
                                <div v-else class="w-30 tr">{{ invoice.tax }}%</div>
                            </div>
                        </div>
                    </div>
                    <div v-if="invoice.hasOthersCharges" class="ph3-l ph2">
                        <div class="flex justify-between pb3 font-w1">
                            <div class="w-30-l"></div>
                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom">
                                <div class="w-30 tl">{{ invoice.others }}</div>
                                <div class="w-30 tr">
                                    {{
                                        formatAmount(invoice.othersCharges, invoice?.currency || $store.state.Settings?.currency)
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="ph3-l ph2">
                        <div class="flex justify-between pb3 font-w1">
                            <div class="w-30-l"></div>
                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom b">
                                <div class="w-30 tl">Total</div>
                                <div class="w-30 tr" :style="'color:' + color + ';'">
                                    {{ formatAmount(invoice.totalAmount, invoice?.currency || $store.state.Settings?.currency) }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="invoice?.deposit && invoice?.deposit > 0" class="ph3-l ph2">
                        <div class="flex justify-between pb3 font-w1">
                            <div class="w-30-l"></div>
                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom b">
                                <div class="w-30 tl">Amount Paid</div>
                                <div class="w-30 tr" :style="'color:' + color + ';'">
                                    {{
                                        formatAmount(invoice?.deposit || 0, invoice?.currency || $store.state.Settings?.currency)
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="invoice?.deposit && invoice?.deposit > 0" class="ph3-l ph2">
                        <div class="flex justify-between pb3 font-w1">
                            <div class="w-30-l"></div>
                            <div class="w-60-l w-100 pb3 flex justify-between box-border-bottom b">
                                <div class="w-30 tl text-danger">Outstanding</div>
                                <div class="w-30 tr text-danger">
                                    {{
                                        formatAmount(
                                            invoice.totalAmount - (invoice?.deposit || 0),
                                            invoice?.currency || $store.state.Settings?.currency
                                        )
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="lh-copy pv3" style="border-top: 1px solid #e3e8ee; border-bottom: 1px solid #e3e8ee">
                        All amounts are in {{ getCurrencyName(invoice?.currency || 'NGN') }}. Please make the payment before
                        {{ formatDate(invoice.dateDue) }}.
                    </div>
                    <div
                        v-if="invoice.notes"
                        class="lh-copy pv3"
                        style="border-top: 1px solid #e3e8ee; border-bottom: 1px solid #e3e8ee"
                    >
                        <div class="font-w1">Notes:</div>
                        {{ invoice.notes ? invoice.notes : '' }}
                    </div>
                </div>
            </div>
        </div>

        <div v-if="invoice?.paymentInfo" class="box-border mv4" style="background: #fbfcfe">
            <div class="flex justify-between box-border-bottom pa3">
                <div>Previous Payment(s)</div>
            </div>
            <div
                v-for="(data, index) of invoice.paymentInfo"
                v-bind:key="index"
                class="pa3"
                style="border-bottom: 1px solid #e3e8ee"
            >
                <div class="flex justify-between">
                    <div class="w-30">{{ data.account }}</div>
                    <div class="w-50 b">
                        {{ formatAmount(data.amount, invoice?.currency || $store.state.Settings?.currency) }}
                    </div>
                    <div class="w-20 b">{{ data?.date ? formatDate(data.date) : '' }}</div>
                </div>
            </div>
        </div>

        <div class="box-border mv4" style="background: #fbfcfe">
            <div class="flex justify-between box-border-bottom pa3">
                <div>Selected payment option</div>
            </div>
            <div v-for="item of invoice.bankDetails" v-bind:key="item.bankAccountNumber" class="pa3">
                <div class="flex flex-wrap justify-between items-center pa3 box-border">
                    <div>
                        <div class="pb2 b">Bank Transfer</div>
                        <div class="pb2">Pay by making a bank transfer to the recievers bank account</div>
                        <div class="flex justify-between">
                            <div class="w-30">Bank Name:</div>
                            <div class="w-70 b">{{ item.bankName }}</div>
                        </div>
                        <div class="flex justify-between">
                            <div class="w-30">Account Number:</div>
                            <div class="w-70 b">{{ item.bankAccountNumber }}</div>
                        </div>
                        <div class="flex justify-between">
                            <div class="w-30">Account Name:</div>
                            <div class="w-70 b">{{ item.bankAccountName }}</div>
                        </div>
                    </div>
                    <div class="pt0-l pt3">
                        <img src="imgs/bank.svg" alt="" />
                    </div>
                </div>
            </div>
            <div v-if="invoice?.paystackLink" class="pa3">
                <div class="flex flex-wrap justify-between items-center pa3 box-border copy-text-wrapper">
                    <div>
                        <div class="pb2 b">Paystack</div>
                        <div>Pay through the secure paystack API</div>
                        <div class="flex flex-wrap justify-between items-center pb2">
                            <a :href="invoice.paystackLink" target="_blank" class="pr2">{{ invoice.paystackLink }}</a>
                            <button @click="doCopy" class="pl0 pr0 pb0 pt0">
                                <span class="icon">
                                    <font-awesome-icon icon="fa-solid fa-copy" />
                                </span>
                            </button>
                        </div>
                    </div>
                    <div class="pt0-l pt3">
                        <img :src="require('@/assets/images/paystack.svg')" alt="paystack logo" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import { copyText } from 'vue3-clipboard'
import InvoiceStatus from '@/views/Invoice/widgets/InvoiceStatus'
import { formatAmount, getCurrencyName, getDaysFromToday, formatDate } from '@/utils/lib'

export default {
    name: 'LetterHeadInvoice',
    components: { InvoiceStatus },
    props: {
        invoice: { type: Object },
        color: { type: String },
        font: { type: String },
    },

    setup(props) {
        const store = useStore()

        const orgData = computed(() => store?.state?.Auth?.userData)
        const orgInitials = computed(() => store?.state?.Auth?.orgInitials)
        const orgImage = computed(() => store?.state?.Auth?.orgImage)
        const userInitials = computed(() => store?.state?.Auth?.userInitials)

        const doCopy = () => {
            copyText(props.invoice.paystackLink, undefined, (error, event) => {
                if (!error) {
                    let copyText = document.querySelector('.copy-text-wrapper')
                    copyText.classList.add('active')
                    setTimeout(function () {
                        copyText.classList.remove('active')
                    }, 2500)
                }
            })
        }

        return {
            orgData,
            orgInitials,
            orgImage,
            userInitials,
            formatAmount,
            getCurrencyName,
            getDaysFromToday,
            formatDate,
            doCopy,
        }
    },
}
</script>
<style>
.copy-text-wrapper button {
    border: none !important;
    outline: none !important;
    border-radius: 10px;
    cursor: pointer;
    background-color: unset !important;
    color: unset !important;
}

.copy-text-wrapper button:active {
    background: #809ce2;
}
.copy-text-wrapper button:before {
    content: 'Copied';
    position: absolute;
    top: -45px;
    right: 0px;
    background: #5c81dc;
    padding: 8px 10px;
    border-radius: 20px;
    font-size: 15px;
    display: none;
}
.copy-text-wrapper button:after {
    content: '';
    position: absolute;
    top: -20px;
    right: 25px;
    width: 10px;
    height: 10px;
    background: #5c81dc;
    transform: rotate(45deg);
    display: none;
}
.copy-text-wrapper.active button:before,
.copy-text-wrapper.active button:after {
    display: block;
}
@media screen and (max-width: 660px) {
    .longText{
    white-space: nowrap; 
    overflow: hidden; 
    text-overflow: ellipsis;
}}
</style>
